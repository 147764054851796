import { CommonModule } from '@angular/common';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';

import { ImageSliderComponent } from '../components/image-slider/image-slider.component';

@NgModule({
    declarations: [
        ImageSliderComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        MatCarouselModule.forRoot()
    ],
    exports: [
        ImageSliderComponent
    ],
})

export class ImageSliderModule { }
import { Component } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IgridImage } from '../../models/grid-layout.model';

@Component({
  selector: 'app-grid-layout',
  templateUrl: './grid-layout.component.html',
  styleUrls: ['./grid-layout.component.scss']
})
export class GridLayoutComponent {
  public cols: Observable<number>;
  public colspan: number;
  public rowspan: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {

    this.cols = this.getColsByBreakpoint();
    this.colspan = 1;
    this.rowspan = 1;
  };
  public services: IgridImage[] = [
    { image:'assets/grid-image/soyabean.png',text:'Soyabean Refined'},
    { image:'assets/grid-image/mustard.png',text:'Mustard Oil'},
    { image:'assets/grid-image/ground nut.png',text:'Groundnut Oil'},
    { image:'assets/grid-image/sunflower.png',text:'Sunflower Oil'},
    { image:'assets/grid-image/mahuaa.png',text:'Mahua Oil'},
    { image:'assets/grid-image/andi.png',text:'Andi Oil'},
    { image:'assets/grid-image/neem.png',text:'Neem Oil'},
    { image:'assets/grid-image/alsi.png',text:'Alsi Oil'},
  ];

  private getColsByBreakpoint() {
    return this.breakpointObserver.observe([
      '(max-width: 359px)',
      '(max-width: 517px)',
      Breakpoints.XSmall,
      '(max-width: 730px)',
      Breakpoints.Small,
      '(max-width: 1100px)',
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).pipe(
      map((result) => {
        if (result.breakpoints['(max-width: 359px)']) { return 1; }
        if (result.breakpoints['(max-width: 517px)']) { return 1; }
        if (result.breakpoints[Breakpoints.XSmall]) { return 2; }
        if (result.breakpoints['(max-width: 730px)']) { return 2; }
        if (result.breakpoints[Breakpoints.Small]) { return 3; }
        if (result.breakpoints['(max-width: 1100px)']) { return 4; }
        if (result.breakpoints[Breakpoints.Medium]) { return 4; }
        if (result.breakpoints[Breakpoints.Large]) { return 4; }
        if (result.breakpoints[Breakpoints.XLarge]) { return 4; }
      })
    );
  }
}

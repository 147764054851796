import { Component } from '@angular/core';

import { IImageSlider, IImageSlidesConfig } from 'src/app/common/models/image-slider.model';

@Component({
  selector: 'app-businessgyani',
  templateUrl: './businessgyani.component.html',
  styleUrls: ['./businessgyani.component.scss']
})

export class BusinessgyaniComponent {

  public slider: IImageSlider = {
    timings: '250ms ease-in',
    autoplay: true,
    interval: 5000,
    color: 'accent',
    maxWidth: 'auto',
    loop: true,
    proportion: 25,
    slides: 3,
    hideArrows: true,
    hideIndicators: true,
    useKeyboard: true,
    useMouseWheel: true,
    orientation: 'ltr',
  }

  public imageSlidesConfig: IImageSlidesConfig = {
    hideOverlay: false,
    overlayColor:'ffffff',
    images: [
      "assets/slider-image/header.png",
      "assets/slider-image/banner 1.jpg"
    ]
  }
}
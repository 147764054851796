import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-office-details',
  templateUrl: './office-details.component.html',
  styleUrls: ['./office-details.component.scss']
})
export class OfficeDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component } from '@angular/core';

import { DomSanitizer } from "@angular/platform-browser";

import { MatIconRegistry } from "@angular/material/icon";
import { IFooter, Iimage } from '../../models/footer.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  footer: IFooter[] = [
    { Heading: 'About Market', ListItem1: 'About Us', ListItem2: 'Contact', ListItem3: 'FAQ', ListItem4: 'Privacy Policy' },
    // { Heading: 'Make Money With Us', ListItem1: 'Shipping Policy', ListItem2: 'My Account', ListItem3: 'Return Policy', ListItem4: 'Contact Us' },
    // { Heading: 'Payment & Shipping', ListItem1: 'Terms Of Use', ListItem2: 'Payment Method', ListItem3: 'Shipping Guide', ListItem4: 'Locations We Ship To', ListItem5: 'Estimated Delivery Time' },
    // { Heading: 'Let Us Help You', ListItem1: 'Your Account', ListItem2: 'Your Orders', ListItem3: 'Shipping Rates And Policies', ListItem4: 'Help' },
  ];

  private customIcons: Array<[string, string]> = [
    ["facebook", "assets/footer/facebook-square.svg"],
    ["linkedin", "assets/footer/linkedin.svg"],
    ["twitter", "assets/footer/twitter-square.svg"],
    ["instagram", "assets/footer/instagram-square.svg"]
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customIcons.forEach(([iconName, iconPath]) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath)
      );
    });
  }

  items = ['At Kaka Kohlu ,we are passionate about bringing you the finest quality Mustard Oil, straight from the heart of mustard-growing regions. With a rich heritage in producing and sourcing the best Mustard Oil, we have earned the trust of families and food enthusiasts across the world.'];

  public images: Iimage[] = [
    { image: 'assets/footer/phonepe.png' },
    { image: 'assets/footer/paytm.png' },
    { image: 'assets/footer/bhim.png' },
    { image: 'assets/footer/amazon.jpg' },
    { image: 'assets/footer/americanexpress.PNG' },
    { image: 'assets/footer/rupay.PNG' },
    { image: 'assets/footer/visa.png' }
  ]
}
<div fxLayout="column">
    <app-header></app-header>
    <app-image-slider [slider]='slider' [imageSlidesConfig]='imageSlidesConfig'></app-image-slider>
    <div class="matCard-container">
        <mat-card>
            <h2>About Us</h2>
            <div fxLayout fxLayoutAlign="space-around" fxLayout.xs="column">
                <div *ngFor="let data of aboutUsImage" class="image-container">
                    <img src="{{data.image}}">
                </div>
            </div>
            <div class="aboutUs-content">

                <h4>Welcome to Kaka Kohlu- Your Trusted Source for Pure and Premium Mustard Oil!</h4>

                At Kaka Kohlu ,we are passionate about bringing you the finest quality Mustard Oil, straight from the
                heart of mustard-growing regions. With a rich heritage in producing and sourcing the best Mustard Oil,
                we've earned the trust of families and food enthusiasts across the world.

                <h4>Our Story</h4>

                Our journey began with a simple goal - to provide you with the purest and most authentic Mustard Oil. We
                have a long-standing tradition of selecting the best mustard seeds, crushing them using traditional
                methods, and extracting the golden elixir that is Mustard Oil.

                <h4>Why Kaka Kohlu Mustard Oil?</h4>

                Pure and Unadulterated: We guarantee that our Mustard Oil is pure, free from any additives or chemic…

            </div>
        </mat-card>
    </div>
    <app-footer></app-footer>
</div>
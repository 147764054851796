<div class="gyani" fxFlex>
    <h3>OUR PRODUCTS</h3>
    What we bring to you
    <mat-grid-list cols="{{cols | async}}" gutterSize="30px" rowHeight="4:3">
        <mat-grid-tile *ngFor="let data of services" [colspan]="colspan" [rowspan]="rowspan" fxLayout="column">
           <div  fxLayout="column"> <a class="image-container" href="{{data.href}}">
                <img src="{{data.image}}"></a>
                <h2>{{data.text}}</h2></div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
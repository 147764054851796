import { Component } from '@angular/core';

import { IImageSlider, IImageSlidesConfig } from 'src/app/common/models/image-slider.model';
import { Iimage } from '../../models/about-us.model';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})

export class AboutUsComponent {

  aboutUsImage: Iimage[] = [
    { image: 'assets/aboutUs/about 1.png' },
    { image: 'assets/aboutUs/about 2.png' },
    { image: 'assets/aboutUs/about 3.png' }
  ];
  
  public slider: IImageSlider = {
    timings: '250ms ease-in',
    autoplay: true,
    interval: 2000,
    color: 'accent',
    maxWidth: 'auto',
    loop: true,
    proportion: 25,
    slides: 3,
    hideArrows: true,
    hideIndicators: true,
    useKeyboard: true,
    useMouseWheel: true,
    orientation: 'ltr'
  }

  public imageSlidesConfig: IImageSlidesConfig = {
    hideOverlay: false,
    images: [
      "assets/slider-image/banner 3.png",
      "assets/slider-image/banner 4.png"
    ]
  }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';

import { ScrollToTopComponent } from '../components/scroll-to-top/scroll-to-top.component';

@NgModule({
    declarations: [
        ScrollToTopComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        ScrollToTopComponent
    ],
})

export class ScrollToTopModule { }
import { Component, Input } from '@angular/core';

import { IImageSlider, IImageSlidesConfig } from '../../models/image-slider.model';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})

export class ImageSliderComponent {

  @Input() imageSlidesConfig : IImageSlidesConfig;
  @Input() slider : IImageSlider;

}